import React from "react"

const Tag = props => {
	let off = ''
	let _off2 = ''
	let off2 = ''
	let off3 = ''
	let point = ''
	let tsuruha_point = ''
	let per = ''
	let timesale = ''
	let pointBack = ''
	let pointBack2 = ''
	let tag1 = ''
	let tag2 = ''
	let tag3 = ''
	let tag4 = ''
	let tag5 = ''
	let tag6 = ''
	let tag7 = ''
	let tag8 = ''
	let tag9 = ''
	let tag10 = ''
	let used1 = ''
	let used2 = ''
	if(props.item.txt){
		if(props.item.txt.match(/[0-9]+%OFFクーポン/))
		{
			off = props.item.txt.match(/([0-9]+%OFF)クーポン/)[1]
			if(off === '0%OFF'){
				off = ''
			}
			if(props.item.txt.match(/3点10%OFF/)){
				off += '(3点)' 
			}
		}
		if(props.item.txt.match(/[0-9]+円OFFクーポン/))
		{
			_off2 = props.item.txt.match(/([0-9]+)円OFFクーポン/)[1]
			off2 = `${PriceKanma(_off2)}円OFF`
		}
		if(props.item.txt.match(/[0-9]+円引き/))
		{
			_off2 = props.item.txt.match(/([0-9]+)円引き/)[1]
			off2 = `${PriceKanma(_off2)}円引き`
		}
		// if(props.item.txt.match(/P&G\(PayPay[0-9]+ポイント\)/))
		// {
		// 	point = props.item.txt.match(/P&G\(PayPay([0-9]+)ポイント\)/)[1]
		// 	off3 = `P&G(PayPay${point}㌽)`
		// }
		// if(props.item.txt.match(/P&G×yahoo\(PayPay[0-9]+ポイント\)/))
		// {
		// 	point = props.item.txt.match(/P&G×yahoo\(PayPay([0-9]+)ポイント\)/)[1]
		// 	off3 = `PayPay＋15%(${point}㌽)`
		// }
		if(props.item.txt.match(/パンパース\(PayPay[0-9]+ポイント\)/))
		{
			point = props.item.txt.match(/パンパース\(PayPay([0-9]+)ポイント\)/)[1]
			off3 = `パンパース(PayPay${point}㌽)`
		}
		if(props.item.txt.match(/ツルハグループ\([0-9]+ポイント\)/))
		{
			tsuruha_point = props.item.txt.match(/ツルハグループ\(([0-9]+)ポイント\)/)[1]
			off3 = `HAPPAY×P＆G(${tsuruha_point}㌽)`
		}
		if(props.item.txt.match(/タイムセール/))
		{
			timesale = 'タイムセール'
		}
		else if(props.item.txt.match(/Black Friday Sale/))
		{
			timesale = 'タイムセール'
		}
		else if(props.item.txt.match(/いちばの日/))
		{
			timesale = 'いちばの日 ダイヤモンド会員(＋3倍㌽)'
		}
		if(props.item.txt.match(/ポイントバック/))
		{
			per = props.item.txt.match(/([0-9]+)%ポイントバック/)[1]
			pointBack = `スーパーDEAL ${per}%㌽`
		}
		if(props.item.txt.match(/ポイント還元1/))
		{
			pointBack2 = `スーパーDEAL ＋1%㌽`
		}

	}
	if(props.item.txt.match(/GOONPLUS2441/)){
		tag1 = 'クーポンコード'
	}
	if(props.item.txt.match(/10%ポイント還元/))
	{
		point = props.item.txt.match(/10%ポイント還元\(([0-9]+)\)/)[1]
		tag2 = `10%㌽還元(＋${point}㌽)`
	}
	if(props.item.txt.match(/らくベビ300/)){
		tag3 = 'らくベビ(＋300㌽)'
	}
	if(props.item.txt.match(/らくベビ600/)){
		tag3 = 'らくベビ(＋600㌽)'
	}
	if(props.item.txt.match(/リピート・/)){
		tag4 = 'らくベビリピート(＋100㌽)'
	}
	if(props.item.txt.match(/サイズアップ・/)){
		tag5 = 'らくベビサイズアップ(＋500㌽)'
	}
	if(props.item.txt.match(/GOONPLU使用済S2441/)){
		used1 = 'クーポンコード'
	}
	if(props.item.txt.match(/らくベビ使用済300/)){
		used2 = 'らくベビ(＋300㌽)'
	}
	if(props.item.txt.match(/らくベビ使用済600/)){
		used2 = 'らくベビ(＋600㌽)'
	}
	if(props.item.txt.match(/マラソン/)){
		tag1 = 'マラソン9店(＋9倍㌽)'
	}
	if(props.item.txt.match(/スーパーセール/)){
		tag1 = 'スーパーSALE10店(＋9倍㌽)'
	}
	if(props.item.txt.match(/ワンダフル/)){
		tag1 = 'ワンダフルデー(＋2倍㌽)'
	}
	if(props.item.txt.match(/ママ割\(マラソン\)/)){
		tag2 = 'ママ割(＋1倍㌽)'
	}
	if(props.item.txt.match(/ママ割\(スーパーセール\)/)){
		tag2 = 'ママ割(＋1倍㌽)'
	}
	if(props.item.txt.match(/39ショップ/)){
		tag3 = '39ショップ(＋1倍㌽)'
	}
	if(props.item.txt.match(/育児の日/))
	{
		tag4 = '育児の日(＋6倍㌽)'
	}
	if(props.item.txt.match(/0のつく日/)){
		tag5 = '0のつく日(＋3倍㌽)'
	}
	if(props.item.txt.match(/5のつく日/)){
		tag5 = '5のつく日(＋3倍㌽)'
	}
	if(props.item.txt.match(/日用品/)){
		tag6 = '日用品(＋4倍㌽)'
	}
	if(props.item.txt.match(/勝ったら倍/)){
		tag7 = '勝ったら倍(＋1倍㌽)'
	}
	if(props.item.txt.match(/勝ったら3倍/)){
		tag7 = 'W勝利!勝ったら倍(＋2倍㌽)'
	}
	if(props.item.txt.match(/48時間限定/)){
		tag8 = '2ショップ購入(＋2倍㌽)'
	}
	if(props.item.txt.match(/ゲリラ/)){
		tag9 = 'ポイントアップ(＋1倍㌽)'
	}
	if(props.item.txt.match(/カテゴリー買いまわり/)){
		tag10 = '3カテゴリー(＋9倍㌽)'
	}
	// if(props.item.href.match(/tsukasa1/)){
	// 	if(props.item.href.match(/10000283cp/)){
	// 		tag7 = '3/5 19:00-19:59限定'
	// 	}
	// 	else if(props.item.href.match(/10000284cp/)){
	// 		tag7 = '3/6 17:00-17:59限定'
	// 	}
	// }
	// if(props.item.href.match(/mamababy/)){
	// 	if(props.item.href.match(/7252901/)){
	// 		tag10 = '6/10 23:00-23:29限定'
	// 	}
	// }
	if(props.item.txt.match(/三太郎の日/)){
		tag1 = '三太郎の日(＋5%㌽)'
	}
	if(props.item.txt.match(/超超祭/)){
		per = props.item.txt.match(/超超祭\(＋([0-9]+)%\)/)[1]
		tag2 = `㌽超超祭(＋${per}%㌽)`
	}
	if(props.item.txt.match(/ポイント倍々/)){
		if(props.item.txt.match(/ポイント倍々\(＋7%\)/)){
			tag2 = `㌽倍々5商品(＋7%㌽)`
		}
		else if(props.item.txt.match(/ポイント倍々\(＋3\.5%\)/)){
			tag2 = `㌽倍々3商品(＋3.5%㌽)`
		}
	}
	if(props.item.txt.match(/プレミアム特典 ポイント\+1%/)){
		tag3 = 'auスマプレ(＋1%㌽)'
	}
	if(props.item.txt.match(/プレミアム特典 送料無料/)){
		tag3 = 'auスマプレ(送料無料)'
	}
	if(props.item.txt.match(/ストアラリー/)){
		tag1 = 'ストアラリー10店(＋9%㌽)'
	}
	if(props.item.txt.match(/5の付く日/)){
		tag2 = '5のつく日(＋4%商品券)'
	}
	if(props.item.txt.match(/買う！買う！サンデー/)){
		tag3 = '買う！買う！サンデー(＋4%㌽)'
	}
	if(props.item.txt.match(/プレミアムな日曜日/)){
		tag4 = 'プレミアムな日曜日(＋5%㌽)'
	}
	if(props.item.txt.match(/HAPPYDAY/)){
		tag5 = 'HAPPYDAY(＋4%㌽)'
	}
	if(props.item.txt.match(/ファーストデー/)){
		tag6 = 'ファーストデー(＋3%㌽)'
	}
	if(props.item.txt.match(/LOHACOさらに5付く日/)){
		tag6 = '5のつく日(＋5%㌽)'
	}
	if(props.item.txt.match(/LYPプレミアム/)){
		tag7 = 'LYPプレミアム(＋2%㌽)'
	}
	if(props.item.txt.match(/ボーナスストア/)){
		tag8 = 'ボーナスストア(＋4%㌽)'
	}
	if(props.item.txt.match(/ビッグボーナス/)){
		tag8 = 'ビッグボーナス(＋6%㌽)'
	}
	if(props.item.txt.match(/LOHACO日曜日は誰でも/)){
		tag9 = '日曜日は誰でも(＋5%㌽)'
	}

  return (
		<>
			{ (off.length > 0 || off2.length > 0 || off3.length > 0 || timesale.length > 0 || pointBack.length > 0 || pointBack2.length > 0 || tag1.length > 0 || tag2.length > 0 || tag3.length > 0 || tag4.length > 0 || tag5.length > 0 || tag6.length > 0 || tag7.length > 0 || tag8.length > 0 || tag9.length > 0 || tag10.length > 0 || used1.length > 0 || used2.length > 0) && (
				<br />
			)}
			{ off.length > 0 && (
				<><span className="tag is-danger" style={{fontSize:`0.7rem`}}>{off}</span>&nbsp;</>
			)}
			{ off2.length > 0 && (
				<><span className="tag is-danger" style={{fontSize:`0.7rem`}}>{off2}</span>&nbsp;</>
			)}
			{ timesale.length > 0 && (
				<><span className="tag is-danger" style={{fontSize:`0.7rem`}}>{timesale}</span>&nbsp;</>
			)}
			{ pointBack.length > 0 && (
				<><span className="tag is-danger" style={{fontSize:`0.7rem`}}>{pointBack}</span>&nbsp;</>
			)}
			{ pointBack2.length > 0 && (
				<><span className="tag is-danger" style={{fontSize:`0.7rem`}}>{pointBack2}</span>&nbsp;</>
			)}
			{ tag1.length > 0 && (
				<><span className="tag is-danger" style={{fontSize:`0.7rem`}}>{tag1}</span>&nbsp;</>
			)}
			{ tag2.length > 0 && (
				<><span className="tag is-danger" style={{fontSize:`0.7rem`}}>{tag2}</span>&nbsp;</>
			)}
			{ tag3.length > 0 && (
				<><span className="tag is-danger" style={{fontSize:`0.7rem`}}>{tag3}</span>&nbsp;</>
			)}
			{ tag4.length > 0 && (
				<><span className="tag is-danger" style={{fontSize:`0.7rem`}}>{tag4}</span>&nbsp;</>
			)}
			{ tag5.length > 0 && (
				<><span className="tag is-danger" style={{fontSize:`0.7rem`}}>{tag5}</span>&nbsp;</>
			)}
			{ tag6.length > 0 && (
				<><span className="tag is-danger" style={{fontSize:`0.7rem`}}>{tag6}</span>&nbsp;</>
			)}
			{ tag7.length > 0 && (
				<><span className="tag is-danger" style={{fontSize:`0.7rem`}}>{tag7}</span>&nbsp;</>
			)}
			{ tag8.length > 0 && (
				<><span className="tag is-danger" style={{fontSize:`0.7rem`}}>{tag8}</span>&nbsp;</>
			)}
			{ tag9.length > 0 && (
				<><span className="tag is-danger" style={{fontSize:`0.7rem`}}>{tag9}</span>&nbsp;</>
			)}
			{ tag10.length > 0 && (
				<><span className="tag is-danger" style={{fontSize:`0.7rem`}}>{tag10}</span>&nbsp;</>
			)}
			{ off3.length > 0 && (
				<><span className="tag is-danger" style={{fontSize:`0.7rem`}}>{off3}</span>&nbsp;</>
			)}
			{ used1.length > 0 && (
				<><span className="tag is-success" style={{fontSize:`0.7rem`}}><span style={{textDecoration:`line-through`}}>{used1}</span>使用済</span>&nbsp;</>
			)}
			{ used2.length > 0 && (
				<><span className="tag is-success" style={{fontSize:`0.7rem`}}><span style={{textDecoration:`line-through`}}>{used2}</span>使用済</span>&nbsp;</>
			)}
		</>
  )
}

function PriceKanma(price){
	var length = price.length;
	var price_kanma;
	if(length > 3)
	{
		price_kanma = price.slice(0,length-3) + "," + price.slice(length-3);
	}
	else
	{
		price_kanma = price.slice();
	}
	return price_kanma;
}

export default Tag
